<template>
	<div>
		<!-- Loan Data -->
		<vcl-facebook v-show="loading" />
		<b-row v-show="!loading">
			<b-form-group class="col-md-6 mb-2" :label="$t('Family Name', 'Family Name')">
				<b-form-input v-model="form.WifeName" type="text" readonly />
			</b-form-group>
			<b-form-group class="col-md-6 mb-2" :label="$t('Family ID Number', 'Family ID Number')">
				<b-form-input v-model="form.WifeIDNumber" type="text" readonly />
			</b-form-group>
		</b-row>
	</div>
</template>
<script>
	import { mapGetters, mapActions } from "vuex"
	export default {
		props: {
			result: {
				type: Array,
				required: true,
			},
		},
		data() {
			return {
				loading: true,
				form: {
					WifeName: "",
					WifeIDNumber: "",
				},
			}
		},
		computed: {
			...mapGetters({}),
		},
		mounted() {
			this.getLoanData()
		},
		methods: {
			...mapActions({}),
			getLoanData() {
				/**
				 * Waiting for a render data before result done
				 */
				if (this.result.length > 0) {
					this.loading = false
					this.form.WifeName = this.result[0].familyWife
					this.form.WifeIDNumber = this.result[0].familyWifeIdNumber
				}
			},
		},
	}
</script>
