<template>
	<div>
		<!-- Loan Data -->
		<vcl-facebook v-show="loading" />
		<b-row v-show="!loading">
			<b-form-group
				class="col-md-3 mb-2"
				:label="$t( 'Bank Name' , 'Bank Name')"
			>
				<b-form-input
					v-model="form.BankName"
					type="text"
					readonly
				/>
			</b-form-group>
			<b-form-group
				class="col-md-3 mb-2"
				:label="$t( 'Bank Branch' , 'Bank Branch')"
			>
				<b-form-input
					v-model="form.BankBranch"
					type="text"
					readonly
				/>
			</b-form-group>
			<b-form-group
				class="col-md-3 mb-2"
				:label="$t( 'Name Of Account Holder' , 'Name Of Account Holder')"
			>
				<b-form-input
					v-model="form.AccountHolderName"
					type="text"
					readonly
				/>
			</b-form-group>
			<b-form-group
				class="col-md-3 mb-2"
				:label="$t( 'Account Number' , 'Account Number')"
			>
				<b-form-input
					v-model="form.AccountNumber"
					type="text"
					readonly
				/>
			</b-form-group>
		</b-row>
	</div>
</template>
<script>
	import { mapGetters, mapActions } from "vuex"
	export default {
		props: {
			result: {
				type: Array,
				required: true,
			},
		},
		data() {
			return {
				loading: true,
				form: {
					BankName: "",
					BankBranch: "",
					AccountHolderName: "",
					AccountNumber: "",
				},
			}
		},
		computed: {
			...mapGetters({}),
		},
		mounted() {
			this.getLoanData()
		},
		methods: {
			...mapActions({}),
			getLoanData() {
				/**
				 * Waiting for a render data before result done
				 */
				if (this.result.length > 0) {
					this.loading = false
					this.form.BankName = this.result[0].accountBankName
					this.form.BankBranch = this.result[0].accountBankBranch
					this.form.AccountHolderName = this.result[0].accountHolder
					this.form.AccountNumber = this.result[0].accountNumber
				}
			},
		},
	}
</script>
