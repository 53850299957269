<template>
	<div>
		<!-- Loan Data -->
		<vcl-facebook v-show="loading" />
		<b-row v-show="!loading">
			<b-form-group
				class="col-md-6 mb-2"
				:label="$t( 'Phone Number' , 'Phone Number' )"
			>
				<b-form-input
					v-model="form.PhoneNumber"
					type="text"
					readonly
				/>
			</b-form-group>
			<b-form-group
				class="col-md-6 mb-2"
				:label="$t( 'Email' , 'Email' )"
			>
				<b-form-input
					v-model="form.Email"
					type="text"
					readonly
				/>
			</b-form-group>
		</b-row>
	</div>
</template>
<script>
	import { mapGetters, mapActions } from "vuex"
	export default {
		props: {
			result: {
				type: Array,
				required: true,
			},
		},
		data() {
			return {
				loading: true,
				form: {
					PhoneNumber: "",
					Email: "",
				},
			}
		},
		computed: {
			...mapGetters({}),
		},
		mounted() {
			this.getLoanData()
		},
		methods: {
			...mapActions({}),
			getLoanData() {
				/**
				 * Waiting for a render data before result done
				 */
				if (this.result.length > 0) {
					this.loading = false
					this.form.PhoneNumber = this.result[0].contactPhoneNumber
					this.form.Email = this.result[0].contactEmail
				}
			},
		},
	}
</script>
