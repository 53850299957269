<template>
	<div>
		<!-- Loan Data -->
		<vcl-facebook v-show="loading" />
		<b-row v-show="!loading">
			<b-form-group
				class="col-md-3 mb-2"
				:label="$t( 'Country' , 'Country')"
			>
				<b-form-input
					v-model="form.Country"
					type="text"
					readonly
				/>
			</b-form-group>

			<b-form-group
				class="col-md-3 mb-2"
				:label="$t( 'Province' , 'Province')"
			>
				<b-form-input
					v-model="form.Province"
					type="text"
					readonly
				/>
			</b-form-group>
			<b-form-group
				class="col-md-3 mb-2"
				:label="$t( 'District' , 'District')"
			>
				<b-form-input
					v-model="form.District"
					type="text"
					readonly
				/>
			</b-form-group>
			<b-form-group
				class="col-md-3 mb-2"
				:label="$t( 'Sub District' , 'Sub District')"
			>
				<b-form-input
					v-model="form.SubDistrict"
					type="text"
					readonly
				/>
			</b-form-group>
			<b-form-group
				class="col-md-3 mb-2"
				:label="$t( 'Village' , 'Village')"
			>
				<b-form-input
					v-model="form.Village"
					type="text"
					readonly
				/>
			</b-form-group>
			<b-form-group
				class="col-md-9 mb-2"
				:label="$t( 'Address' , 'Address')"
			>
				<b-form-input
					v-model="form.Address"
					type="text"
					readonly
				/>
			</b-form-group>
		</b-row>
	</div>
</template>
<script>
	import { mapGetters, mapActions } from "vuex"
	export default {
		props: {
			result: {
				type: Array,
				required: true,
			},
		},
		data() {
			return {
				loading: true,
				form: {
					Province: "",
					District: "",
					SubDistrict: "",
					Village: "",
					Address: "",
				},
			}
		},
		computed: {
			...mapGetters({}),
		},
		mounted() {
			this.getLoanData()
		},
		methods: {
			...mapActions({}),
			getLoanData() {
				/**
				 * Waiting for a render data before result done
				 */
				if (this.result.length > 0) {
					this.loading = false
					this.form.Country = this.result[0].addressCountry
					this.form.Province = this.result[0].addressProvince
					this.form.District = this.result[0].addressDistrict
					this.form.SubDistrict = this.result[0].addressSubDistrict
					this.form.Village = this.result[0].addressVillage
					this.form.Address = this.result[0].addressAddress
				}
			},
		},
	}
</script>
