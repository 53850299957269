<template>
	<div>
		<b-card
			border-variant="primary"
			:header="$t('Retailer/Supplier' , 'Retailer/Supplier')"
			header-bg-variant="primary"
			header-text-variant="white"
			align="left"
			no-body
			class="mb-2"
		/>
		<vcl-facebook v-show="loading" />
		<b-row v-show="!loading">
			<b-form-group
				class="col-md-6 mb-2"
				:label="$t('Name' , 'Name')"
			>
				<b-form-input
					v-model="form.SupplierName"
					type="text"
					readonly
				/>
			</b-form-group>
			<b-form-group
				class="col-md-6 mb-2"
				:label="$t('Address' , 'Address')"
			>
				<b-form-input
					v-model="form.SupplierAddress"
					type="text"
					readonly
				/>
			</b-form-group>
		</b-row>
	</div>
</template>
<script>
	import { mapGetters, mapActions } from "vuex"
	export default {
		props: {
			result: {
				type: Array,
				required: true,
			},
		},
		data() {
			return {
				loading: true,
				form: {
					SupplierName: "",
					SupplierAddress: "",
				},
			}
		},
		computed: {
			...mapGetters({}),
		},
		mounted() {
			this.getLoanData()
		},
		methods: {
			...mapActions({}),
			getLoanData() {
				/**
				 * Waiting for a render data before result done
				 */
				if (this.result.length > 0) {
					this.loading = false
					this.form.SupplierName = this.result[0].retailerSupplierName
					this.form.SupplierAddress = this.result[0].retailerSupplierAddress 
				}
			},
		},
	}
</script>
