<template>
	<div>
		<!-- Loan Data -->
		<vcl-facebook v-show="loading" />
		<b-row v-show="!loading">
			<div class="col-md-12 mb-2">
				<div class="row">
					<b-form-group class="col-md-6" :label="$t('Status', 'Status')">
						<b-form-input v-model="form.StatusCert" type="text" readonly />
					</b-form-group>
					<b-form-group class="col-md-6" :label="$t('Type', 'Type')">
						<b-form-input v-model="form.TypeCert" type="text" readonly />
					</b-form-group>
				</div>
			</div>
		</b-row>
	</div>
</template>
<script>
	import { mapGetters, mapActions } from "vuex"
	export default {
		props: {
			result: {
				type: Array,
				required: true,
			},
		},
		data() {
			return {
				loading: true,
				form: {
					StatusCert: "",
					TypeCert: "",
				},
			}
		},
		computed: {
			...mapGetters({}),
		},
		mounted() {
			this.getLoanData()
		},
		methods: {
			...mapActions({}),
			getLoanData() {
				/**
				 * Waiting for a render data before result done
				 */
				if (this.result.length > 0) {
					this.loading = false
					this.form.StatusCert = this.result[0].certificationStatus
					this.form.TypeCert = this.result[0].certificationType
				}
			},
		},
	}
</script>
