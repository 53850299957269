<template>
	<div>
		<!-- Loan Data -->
		<vcl-facebook v-show="loading" />
		<b-row v-show="!loading"> 
			<b-form-group class="col-md-6 mb-2" :label="$t('Name', 'Name')">
				<b-form-input v-model="form.Name" type="text" readonly />
			</b-form-group>
			<b-form-group class="col-md-6 mb-2" :label="$t('Farmer Id', 'Farmer Id')">
				<b-form-input v-model="form.farmerId" type="text" readonly />
			</b-form-group>
			<b-form-group class="col-md-2 mb-2" :label="$t('Gender', 'Gender')">
				<b-form-input v-model="form.Gender" type="text" readonly />
			</b-form-group>
			<b-form-group :label="$t('Birth Date', 'Birth Date')" class="col-md-2">
				<b-form-input v-model="form.BirthDate" class="input" type="text" readonly />
			</b-form-group>
			<b-form-group class="col-md-2 mb-2" :label="$t('Age', 'Age')">
				<b-form-input v-model="form.Age" type="text" readonly />
			</b-form-group>
			<b-form-group class="col-md-6 mb-2" :label="$t('ID Type', 'ID Type')">
				<b-form-input v-model="form.IDType" type="text" readonly />
			</b-form-group>
			<b-form-group class="col-md-6 mb-2" :label="$t('ID Number', 'ID Number')">
				<b-form-input v-model="form.IDNumber" type="text" readonly />
			</b-form-group>
		</b-row>
	</div>
</template>
<script>
	import { mapGetters, mapActions } from "vuex"
	export default {
		props: {
			result: {
				type: Array,
				required: true,
			},
		},
		data() {
			return {
				loading: true,
				form: {
					Name: "",
					BirthDate: "",
					IDType: "",
					Gender: "",
					Age: "",
					IDNumber: "",
				},
			}
		},
		computed: {
			...mapGetters({}),
		},
		mounted() {
			this.getLoanData()
		},
		methods: {
			...mapActions({}),
			getLoanData() {
				/**
				 * Waiting for a render data before result done
				 */
				if (this.result.length > 0) {
					this.loading = false
					this.form.farmerId = this.result[0].farmerId
					this.form.Name = this.result[0].dataName
					this.form.Gender = this.result[0].dataGender
					this.form.BirthDate = this.result[0].dataBirthday
					this.form.IDType = this.result[0].dataIdType
					this.getAge()
					this.form.IDNumber = this.result[0].dataIdNumber
				}
			},
			getAge() {
				let date = this.form.BirthDate

				if (date !== "") {
					let today = new Date()
					let birthday = new Date(date)
					let year = 0
					if (today.getMonth() < birthday.getMonth()) {
						year = 1
					} else if (today.getMonth() === birthday.getMonth() && today.getDate() < birthday.getDate()) {
						year = 1
					}
					let age = today.getFullYear() - birthday.getFullYear() - year

					if (age < 0) {
						age = 0
					}
					this.form.Age = age
				}
			},
		},
	}
</script>
