<template>
	<div>
		<b-card
			border-variant="primary"
			:header="$t(Business_text + ' Data', Business_text + ' Data')"
			header-bg-variant="primary"
			header-text-variant="white"
			align="left"
		>
			<vcl-facebook v-show="loading" />
			<b-form v-show="!loading">
				<b-row>
					<b-form-group class="col-md-6" :label="$t(Business_text + ' Type', Business_text + ' Type')">
						<b-form-input v-model="form.BusinessType" type="text" readonly />
					</b-form-group>
					<b-form-group class="col-md-6" :label="$t('Last Transaction', 'Last Transaction')">
						<div class="row" style="background: #e5e7eb; padding: 5px; overflow-y: scroll; height: 100px">
							<div v-for="(row, index) in BusinessEst" :key="index" class="col-6 pt-2">
								{{ row }}
							</div>
						</div>
					</b-form-group>
				</b-row>
			</b-form>
			<div style="height: 1.25rem" />
			<!-- Business List Section -->
			<b-card
				border-variant="primary"
				:header="$t(Business_text + ' List', Business_text + ' List')"
				header-bg-variant="primary"
				header-text-variant="white"
				align="left"
				no-body
				class="mb-2"
			/>

			<b-table
				v-show="!loading"
				id="table-id"
				ref="my-table"
				:key="items.id"
				:items="items"
				:fields="fields"
				sticky-header
				responsive
				no-border-collapse
				show-empty
				primary-key="id"
				style="height: 200px"
			>
				<template #head(id)="data">
					<span>{{ $t(Business_text + data.label, Business_text + " " + data.label) }}</span>
				</template>
				<template #head(Name)="data">
					<span v-if="Business_text === 'Farm'">{{ $t("Farm Name", "Farm Name") }}</span>
					<span v-else>{{ $t(data.label, data.label) }}</span>
				</template>
				<template #head(Size)="data">
					<span v-if="Business_text === 'Farm'">{{ $t("Farm Size", "Farm Size") }} (Ha)</span>
					<span v-else>{{ $t(data.label, data.label) }} (Ha)</span>
				</template>
				<template #head(Production)="data">
					<span v-if="Business_text === 'Farm'">{{ $t("Farm Production", "Farm Production") }} (kg)</span>
					<span v-else>{{ $t(data.label, data.label) }} (kg)</span>
				</template>
				<template #cell(id)="data">
					<span>{{ data.item.id }}</span>
				</template>
				<template #cell(Name)="data">
					<span>{{ data.item.Name }}</span>
				</template>
				<template #cell(Size)="data">
					<span>{{ data.item.Size }}</span>
				</template>
				<template #cell(Production)="data">
					<span>{{ data.item.Production }}</span>
				</template>
				<template #cell(latitude)="data">
					<span>
						<img :src="require('@/assets/images/place.png')" />
						{{ data.item.latitude }}
					</span>
				</template>
				<template #cell(longitude)="data">
					<span>
						<img :src="require('@/assets/images/place.png')" />
						{{ data.item.longitude }}
					</span>
				</template>
			</b-table>
			<div class="text-right">
				<ktv-button :text="$t('Show In Map', 'Show In Map')" color="secondary" @click="showMaps" />
			</div>
			<div v-show="isMaps" class="mt-4">
				<gmaps-map :options="mapOptions">
					<gmaps-marker v-for="(item, i) in itemsMap" :key="i" :position="item" :icon="iconData" />
				</gmaps-map>
			</div>

			<b-overlay :show="isBusy" no-wrap opacity="0.5" />
			<div style="height: 1.25rem" />

			<!-- Selling Transaction Section -->
			<b-card
				border-variant="primary"
				:header="$t('Selling Transaction Last 6 Months', 'Selling Transaction Last 6 Months')"
				header-bg-variant="primary"
				header-text-variant="white"
				align="left"
				no-body
				class="mb-2"
			/>
			<b-form>
				<b-row v-show="!loading">
					<b-form-group class="col-md-6 mb-2" :label="$t('Amount', 'Amount')">
						<b-form-input v-model="form.Amount" type="text" readonly />
					</b-form-group>
					<b-form-group class="col-md-6 mb-2" :label="capitalize($t('Number of transaction', 'Number of transaction'))">
						<b-form-input v-model="form.NumberOfTransaction" type="text" readonly />
					</b-form-group>
				</b-row>
			</b-form>
		</b-card>
	</div>
</template>
<script>
	const columnsBusinessList = [
		{
			label: "Business",
			field: "BusName",
			thClass: "text-left",
		},
		{
			label: "Size",
			field: "BusSize",
			thClass: "text-left",
		},
		{
			label: "Farm Size",
			field: "BusSize",
			thClass: "text-left",
		},
		{
			label: "Production",
			field: "BusProduction",
			thClass: "text-left",
		},
		{
			label: "Lat",
			field: "latitude",
			thClass: "text-left",
		},
		{
			label: "Long",
			field: "longitude",
			thClass: "text-left",
		},
		{
			label: "Action",
			field: "ButtonAct",
			html: true,
			tdClass: "text-center",
			thClass: "text-center",
			sortable: false,
		},
	]

	import { gmapsMap, gmapsMarker } from "x5-gmaps"
	import { KtvButton } from "@/components"
	import FarmerIcon from "@/assets/images/farmer_dist.png"

	export default {
		components: { KtvButton, gmapsMap, gmapsMarker },
		props: {
			result: {
				type: Array,
				required: true,
			},
		},
		data() {
			return {
				loading: false,
				propsImage: { width: 300, height: 200 },
				form: {
					BusinessType: "",
					rowsBusinessList: [],
					Amount: "",
					NumberOfTransaction: "",
				},
				fields: columnsBusinessList,
				items: [],
				currentPage: 0,
				perPage: 5,
				totalItems: 0,
				isBusy: false,
				Business_text: "Businness",
				BusinessEst: [],
				// maps
				isMaps: false,
				itemsMap: [],
				mapOptions: {
					zoom: 3,
					fullscreenControl: false,
					mapTypeControl: false,
					rotateControl: false,
					scaleControl: false,
					streetViewControl: false,
					zoomControl: false,
				},
				iconData: "",
			}
		},
		watch: {
			/* Optionally hide scrollbar when loading */
			isBusy(newVal, oldVal) {
				if (newVal !== oldVal) {
					const tableScrollBody = this.$refs["my-table"].$el
					if (newVal === true) {
						tableScrollBody.classList.add("overflow-hidden")
					} else {
						tableScrollBody.classList.remove("overflow-hidden")
					}
				}
			},
		},
		created() {
			this.getLoanData()
			this.fetchItems()
		},
		mounted() {
			const tableScrollBody = this.$refs["my-table"].$el
			/* Consider debouncing the event call */
			tableScrollBody.addEventListener("scroll", this.onScroll)
		},
		beforeDestroy() {
			/* Clean up just to be sure */
			const tableScrollBody = this.$refs["my-table"].$el
			tableScrollBody.removeEventListener("scroll", this.onScroll)
		},
		methods: {
			capitalize(text) {
				if (!text) {
					return ""
				}

				let arr = []
				let exludeWords = ["of", "the", "by", "with"]
				arr = text.split(" ")

				return arr
					.map((word, i) => {
						return exludeWords.includes(word) && i !== 0 ? [word] : word.charAt(0).toUpperCase() + word.slice(1)
					})
					.join(" ")
			},
			showMaps() {
				this.isMaps = !this.isMaps
			},
			getLoanData() {
				/**
				 * Waiting for a render data before result done
				 */
				if (this.result[0]) {
					if (this.result[0].businessType === "farmer") {
						this.iconData = FarmerIcon
					}

					this.Business_text = this.result[0].businessType === "farmer" ? "Farm" : "Businness"
					this.loading = false
					this.form.BusinessType = this.result[0].businessType === null ? "" : this.result[0].businessType

					if (this.result[0].businessEst !== "") {
						let estMapping = this.result[0].businessEst.split(",")
						let arr = []
						for (let i = 0; i < estMapping.length; i++) {
							const est = estMapping[i].replace("=", " : ")
							arr.push(est)
						}

						this.BusinessEst = arr
					}

					this.form.Amount = this.result[0].sellingTransactionAmount
					this.form.NumberOfTransaction = this.result[0].sellingTransaction

					this.totalItems = this.result[0].businessDetails.length
					let data = []
					this.result[0].businessDetails.forEach((value, key) => {
						data.push({
							id: key + 1,
							Name: value.businessName,
							Size: value.businessSize,
							Production: value.businessProduction,
							latitude: value.latitude,
							longitude: value.longitude,
						})
					})
					this.items = data

					// Maps Google
					this.items.forEach((e) => {
						const obj = {
							lat: parseFloat(e.latitude),
							lng: parseFloat(e.longitude),
						}
						this.itemsMap.push(obj)
					})
				}
			},
			async fetchItems() {
				/* No need to call if all items retrieved */
				if (this.items.length === this.totalItems) return

				/* Enable busy state */
				this.isBusy = true

				/* Missing error handling if call fails */
				const startIndex = (this.currentPage + 1) * this.perPage
				const endIndex = startIndex + this.perPage
				const newItems = await this.callDatabase(startIndex, endIndex)

				/* Add new items to existing ones */
				this.items = this.items.concat(newItems)
				/* Disable busy state */
				this.isBusy = false
			},
			/* mock database method */
			callDatabase(startIndex, endIndex) {
				return new Promise((resolve) => {
					const delay = Math.floor(Math.random() * 1250) + 250
					setTimeout(() => {
						resolve(this.result[0].businessDetails.slice(startIndex, endIndex))
					}, delay)
				})
			},
			onScroll(event) {
				if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
					if (!this.isBusy) {
						this.fetchItems()
					}
				}
			},
		},
	}
</script>

<style>
	.gmaps-map {
		width: 100% !important;
		height: 500px !important;
	}
</style>
