<template>
	<div>
		<b-card
			border-variant="primary"
			:header="$t( 'Document' , 'Document')"
			header-bg-variant="primary"
			header-text-variant="white"
			align="left"
		>
			<vcl-facebook v-show="loading" />
			<b-row v-show="!loading">
				<b-col
					v-for="(row, index) in isDocument"
					:key="index"
					cols="6"
				>
					<b-card
						border-variant="primary"
						:header="row.type"
						header-bg-variant="primary"
						header-text-variant="white"
						align="left" 
						class="mb-2"
					>
						<div>
							<b-img
								center
								:src="imgsdata(row.documentUrl)" 
								class="mb-2 col-md-7"
							/> 
						</div>   
						<b-input-group class="col-md-6 align-self-center mt-2 mb-2">  
							<b-button variant="primary" @click="download(row.documentUrl)">
								Download
							</b-button> 
						</b-input-group>  
					</b-card>
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>
<script>
	import { mapActions } from "vuex"
	export default {
		props:{
			result :{   
				type: Array,
				required: true
			} 
		},
		data() {
			return {
				loading: true, 
				isDocument:[] 
			}
		},
		mounted() {
			this.getDocument()
		},
		methods: {
			...mapActions({}), 
			imgsdata(image) {
				let img = ""
				if (image !== null) {
					const partsOfStr = image.split(",")
					img = partsOfStr[0]
				}
				return img
			},
			download(image){
				let img = ""
				if (image !== null) {
					const partsOfStr = image.split(",")
					img = partsOfStr[0]
				}
				window.location.href = img
			},
			getDocument() {
				/**
				 * Waiting for a render data before result done
				 */ 
				if(this.result.length > 0){
					this.loading = false    
					// Product pocket
					this.isDocument = this.result[0].documents
				}  
			},
		},
	}
</script>

